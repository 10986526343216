<p-multiSelect 
    [options]="list" 
    [(ngModel)]="selectedItem" 
    [placeholder]="list != undefined ? 'Selecione o Gestor' : 'Aguarde, o combo está sendo carregado...'"
    optionLabel="text"
    (ngModelChange)="onSelectionChange($event)"  
    selectedItemsLabel="{0} Item(ns) Selecionado(s)"
    [maxSelectedLabels]="3"
    [style]="{'width':'100%'}"
    [maxSelectedLabels]="1"
    [panelStyle]="{'max-width': '100%'}">
</p-multiSelect>
