import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AtividadeEtapaService } from 'src/app/core/services/atividade-etapa.service';
import { AtividadeOsParcelaVinculadaService } from 'src/app/core/services/atividade-os-parcela-vinculada.service';
import { ComboBase } from 'src/app/models/comboModel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sgc-atividade-etapa-multiselect',
  templateUrl: './atividade-etapa-multiselect.component.html',
  styleUrls: ['./atividade-etapa-multiselect.component.scss']
})
export class AtividadeEtapaMultiselectComponent implements OnInit, OnDestroy {

  list!: ComboBase[];
  @Input() selectedItem!: ComboBase[];
  @Output() itemSelecionado = new EventEmitter();

   private listReadySubject = new Subject<ComboBase[]>(); // Notifica quando a lista está pronta
    listReady$ = this.listReadySubject.asObservable(); // Observable público para componentes externos
    private destroy$ = new Subject<void>(); // Para gerenciar ciclos de vida e evitar vazamentos de memória
  
  

  constructor(private atividadeEtapaService: AtividadeEtapaService,
    private atividadeOsParcelaVinculadaService: AtividadeOsParcelaVinculadaService
  ) { }

  ngOnInit(): void {
    this.getLista();

    this.atividadeOsParcelaVinculadaService.clearFiltersDemandas
      .subscribe(
        () => {
          this.selectedItem = [];
        }
      );
  }

  ngOnDestroy(): void {
    // Notifica o término do ciclo de vida do componente para liberar recursos
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLista() {
    this.atividadeEtapaService.getListaEtapaCombo()
      .pipe(takeUntil(this.destroy$)) // Garante que a assinatura será cancelada ao destruir o componente
      .subscribe(
        (res: any) => {
          if (res) {
            for (let i = 0; i < res.length; i++) {
              res[i].text = res[i].code + ' - ' + res[i].text;
            }
            this.list = res;
            this.listReadySubject.next(this.list); // Notifica que a lista foi inicializada
          }
        },
        (err: any) => {
          console.log(`AtividadeEtapaMultiSelect - getLista ` + err);
        }
      );
  }

  onSelectionChange(selected: any) {
    if (selected.length === this.list.length) {     
      this.itemSelecionado.emit(this.list); // TODA a lista completa
    } else {      
      this.itemSelecionado.emit(selected); // APENAS itens selecionados
    }
  } 
}