import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComboBase } from 'src/app/models/comboModel';
import { LogService } from 'src/app/core/services/log.service';

@Injectable({
    providedIn: 'root'
})
export class AtividadeExecutorService {

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient, private logService: LogService) {
        this.environmentUrl = environment.ApiAtv;
    }
       

    getListaExecutorCombo(fases: string,  gestorIds: number[], etapasIds: number[], tipos: string): Observable<ComboBase[]> {       
        const body = {    
            fases: fases,
            gestorIds: gestorIds,
            etapasIds: etapasIds,            
            tipos: tipos                     
          };
 
        let url = this.environmentUrl + "v1/Usuarios/ListaExecutores";

        this.logService.log(`getListaExecutorCombo url: ${url}`); 
       
        this.logService.log('getListaExecutorCombo Body enviado:', JSON.stringify(body, null, 2));

        return this.httpClient.post<ComboBase[]>(url, body);          
    }     
}