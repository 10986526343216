import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogService {

  log(message: string, ...optionalParams: any[]) {   
    if (environment.enableLogs) {
      console.log(message, ...optionalParams);
    }
  }

  error(message: string, ...optionalParams: any[]) {  
    if (environment.enableLogs) {
      console.error(message, ...optionalParams);
    }
  }
}