import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AtividadeEventsService } from 'src/app/core/services/atividade-events.service';
import { AtividadeService } from 'src/app/core/services/atividade.service';
import { AtividadeEvents, Filter } from 'src/app/models/atividade-events';

@Component({
    selector: 'mf-atividade-events',
    templateUrl: './atividade-events.component.html',
    styleUrls: ['atividade-events.component.scss']
})
export class AtividadeEventsComponent implements OnInit, OnDestroy {
    @Input() idAtividade!: number;
    @Input() tramitacaoReversa!: boolean;
    @Input() dadosTramitacaoReversa!: any;

    atividadeEvents!: AtividadeEvents[];
    atividadeEventsReload!: AtividadeEvents[];
    loading!: boolean;
    filterExecutor: Filter[] = [];
    selectedExecutor: Filter[] = [];
    selectAllExecutor = false;
    isLoaded: boolean = false;
   
    atividadeEventsTramitacaoReversa: AtividadeEvents = {} as AtividadeEvents;

    demandaOnLoadEmitter!: any;

    constructor(
        private atividadeEventsService: AtividadeEventsService,
        private atividadeService: AtividadeService
    ) { }

    ngOnDestroy(): void {
        if (this.demandaOnLoadEmitter !== undefined) {
            this.demandaOnLoadEmitter.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.getAtividadeEventos();       

        this.demandaOnLoadEmitter = this.atividadeService.atividadeEvents
            .subscribe(() => {
                this.getAtividadeEventos();
            },
                err => { }
            );

        this.atividadeService.demandaOnLoad
            .subscribe(data => {
                this.idAtividade = data.idAtividade;
                this.atividadeEvents = [];
                this.getAtividadeEventos();
            });
    }

    getAtividadeEventos() {
        this.loading = true;
        this.atividadeEventsService.getAtividadeEventos(this.idAtividade)
            .subscribe(data => {
                if (this.tramitacaoReversa) {
                    this.atividadeEventsTramitacaoReversa.idAtividade = this.idAtividade;
                    this.atividadeEventsTramitacaoReversa.nomeStatusAnterior = this.dadosTramitacaoReversa.nomeStatus;
                    this.atividadeEventsTramitacaoReversa.etapaNome = this.dadosTramitacaoReversa.etapaNome;
                    this.atividadeEventsTramitacaoReversa.etapaCor = this.dadosTramitacaoReversa.etapaCor;
                    this.atividadeEventsTramitacaoReversa.operacao = 'TRAMITAÇÃO REVERSA';

                    data.push(this.atividadeEventsTramitacaoReversa);
                }

                this.atividadeEvents = data;
                this.atividadeEventsReload = data;
                this.fillFilterExecutores();
                this.loading = false;
            }
            );
    }

    fillFilterExecutores()
    {
        const distinctExecutores = [...new Set(this.atividadeEvents.map(executor => executor.tecnicoAlocado))];
        this.filterExecutor = distinctExecutores.map(nome => ({ value: nome }));
        this.isLoaded = true;
    }

    onChangeFilter(event: any) {          
          this.selectAllExecutor = event.checked;
          if (event.value != null) {
            this.selectedExecutor = event.value;
            const executoresSelecionados = this.selectedExecutor.map((executor: any) => executor.value);
            this.atividadeEvents = this.atividadeEventsReload.filter(executor => executoresSelecionados.includes(executor.tecnicoAlocado));              
          } else {
            this.selectedExecutor = [];
          }
          this.selectAllExecutor = true;        
    }

    onSelectAllChange(event: any) { 
          this.selectedExecutor = event.checked ? [...this.filterExecutor] : [];
          this.selectAllExecutor = event.checked;

          if(this.selectAllExecutor){
            const executoresSelecionados = this.selectedExecutor.map((executor: any) => executor.value);
            this.atividadeEvents = this.atividadeEventsReload.filter(executor => executoresSelecionados.includes(executor.tecnicoAlocado));  
          }        
          else{
            this.atividadeEvents = this.atividadeEventsReload;
          }          
    }
}