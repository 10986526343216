import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/core/models/user';
import { UserService } from 'src/app/core/services/user.service';
import { UiService } from 'src/app/core/ui/ui.service';
import { Menu } from 'src/app/models/others/menu.interface';
import { MENU } from '../../constants/menu';
import { url } from 'inspector';

@Component({
  selector: 'sgc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  currentUser!: User;
  userAcronym!: string;

  isDesktop$!: Observable<boolean>;

  displayMenu: boolean = false;

  menu: Array<Menu> = MENU;

  items = [
    {
      label: 'Home',
      icon: 'pi pi-home',
      routerLink: '/home',
    },
    {
      label: 'Demandas',
      icon: 'pi pi-book',
      routerLink: '/demandas',
    },
    {
      label: 'Configuração',
      icon: 'pi pi-fw pi-pencil',
      items: [
        {
          label: 'Etapas',
          icon: 'pi pi-sort-alt',
          routerLink: '/etapa/lista',
        },
        {
          label: 'Checklist',
          icon: 'pi pi-check-square',
          routerLink: '/checklist/templates'
        },
        {
          label: 'Itens do Checklist',
          icon: 'pi pi-check-circle',
          routerLink: '/checklist/template/layout'
        },
        {
          label: 'Fluxo',
          icon: 'pi pi-arrow-right-arrow-left',
          routerLink: '/transition-flow/fluxo'
        }
      ]
    },    
    {
      label: 'Tratamento Prazos',
      icon: 'pi pi-clock',
      routerLink: '/tratamento-prazos',
    },
    {
      label: 'Tratamento Diligência',
      icon: 'pi pi-exclamation-circle',
      routerLink: '/tratamento-diligencias',
    },
    {      
      label: 'Relátorio Quantitativo',
      icon: 'pi pi-print',
      items: [
        {
          label: 'Etapa',
          icon: 'pi pi-sort-alt',
          routerLink: '/relatorio-quantitativo',
        },
        {
          label: 'Produtividade',
          icon: 'pi pi-check-square',
          routerLink: '/relatorio-quantitativo-executor',
        },        
      ]
    },   
    {
      label: 'Agendamento',
      icon: 'pi pi-calendar',
      routerLink: '/agendamento',
    },
  ];

  readonly AVATAR_COLOR = '#ba40ef';

  constructor(private userService: UserService, private router: Router, private uiService: UiService) { }

  ngOnInit(): void {
    this.onGetUser();

    this.isDesktop$ = this.uiService.onDetectDevice(900);
  }

  logout(): void {
    this.userService.logout();
    this.router.navigate(['/login']);
  }


  onGetUser(): void {
    this.userService.getUser().subscribe({
      next: (response) => {
        if (response) {
          this.currentUser = response;

          const fullName = response.Nome.split(' ')

          const firstName = fullName[0];
          const lastName = fullName[fullName.length - 1];

          const firstletter = firstName[0].toLocaleUpperCase();
          const lastletter = lastName[0].toLocaleUpperCase();

          this.userAcronym = `${firstletter}${lastletter}`;
        }
      },
      error: () => null
    });

  }
}
