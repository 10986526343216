import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AtividadeGestorService } from 'src/app/core/services/atividade-gestor.service';
import { ComboBase } from 'src/app/models/comboModel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sgc-atividade-gestor-multiselect',
  templateUrl: './atividade-gestor-multiselect.component.html',
  styleUrls: ['./atividade-gestor-multiselect.component.scss']
})
export class AtividadeGestorMultiselectComponent implements OnInit, OnDestroy {

  list!: ComboBase[];
  @Input() selectedItem!: ComboBase[];
  @Output() itemSelecionado = new EventEmitter();

    private listReadySubject = new Subject<ComboBase[]>(); // Notifica quando a lista está pronta
    listReady$ = this.listReadySubject.asObservable(); // Observable público para componentes externos
    private destroy$ = new Subject<void>(); // Para gerenciar ciclos de vida e evitar vazamentos de memória

  constructor(private atividadeGestorService: AtividadeGestorService
    
  ) { }
   
  ngOnInit(): void {
    this.getLista();
       
  }

  ngOnDestroy(): void {
    // Notifica o término do ciclo de vida do componente para liberar recursos
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLista() {
    this.atividadeGestorService.getListaGestorCombo()
      .pipe(takeUntil(this.destroy$)) // Garante que a assinatura será cancelada ao destruir o componente
      .subscribe(
        (res: any) => {
          if (res) {
            for (let i = 0; i < res.length; i++) {
              res[i].value = res[i].gestorId;  
              res[i].text = res[i].gestorId + ' - ' + res[i].nomeGestor;
              res[i].code =  res[i].etapaIds;              
            }
            this.list = res;
            this.listReadySubject.next(this.list); // Notifica que a lista foi inicializada
          }
        },
        (err: any) => {
          console.log(`AtividadeGestorMultiSelect - getLista ` + err);
        }
      );
  }

  onSelectionChange(selected: any) {
    if (selected.length === this.list.length) {     
      this.itemSelecionado.emit(this.list); // TODA a lista completa
    } else {      
      this.itemSelecionado.emit(selected); // APENAS itens selecionados
    }
  } 
}



