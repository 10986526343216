import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AtividadeExecutorService } from 'src/app/core/services/atividade-executor.service';
import { ComboBase } from 'src/app/models/comboModel';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogService } from 'src/app/core/services/log.service';


@Component({
  selector: 'sgc-atividade-executor-multiselect',
  templateUrl: './atividade-executor-multiselect.component.html',
  styleUrls: ['./atividade-executor-multiselect.component.scss']
})
export class AtividadeExecutorMultiselectComponent {
  list!: ComboBase[];
  @Input() selectedItem!: ComboBase[];
  @Input() fases!: string;
  @Input() etapasIds!: number[];
  @Input() gestorIds!: number[];
  @Input() tipos!: string;

  @Output() itemSelecionado = new EventEmitter();
 

  private listReadySubject = new Subject<ComboBase[]>(); // Notifica quando a lista está pronta
  listReady$ = this.listReadySubject.asObservable(); // Observable público para componentes externos
  private destroy$ = new Subject<void>(); // Para gerenciar ciclos de vida e evitar vazamentos de memória
  


  constructor(private atividadeExecutorService: AtividadeExecutorService, private logService: LogService   
  ) { }

  ngOnInit(): void {
    const checkEtapas = interval(500)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.etapasIds && this.etapasIds.length > 0 && this.gestorIds && this.gestorIds.length > 0)  {
          this.logService.log('etapasIds preenchido:', this.etapasIds);
          this.logService.log('gestorIds preenchido:', this.gestorIds);

          this.getLista(this.fases, this.gestorIds ,this.etapasIds, this.tipos);
          checkEtapas.unsubscribe(); // Para o intervalo após a primeira execução
        }
      });
  }

  ngOnDestroy(): void {
    // Notifica o término do ciclo de vida do componente para liberar recursos
    this.destroy$.next();
    this.destroy$.complete();
  }


  getLista(fases: string,  gestorIds: number[], etapasIds: number[], tipos: string): void {
    this.atividadeExecutorService.getListaExecutorCombo(fases, gestorIds, etapasIds, tipos)
      .pipe(takeUntil(this.destroy$)) // Garante que a assinatura será cancelada ao destruir o componente
      .subscribe(
        (res: any) => {
          
          this.list = [];  
          if (res) {
            for (let i = 0; i < res.length; i++) {   
              res[i].value = res[i].executorId;          
              res[i].text = res[i].executorId + ' - ' + res[i].nomeExecutor;
              res[i].code = res[i].etapaIds;           
              
            }
            this.list = res;  
            this.logService.log(`getListaExecutorCombo - AtividadeExecutorMultiSelect - Quantidade: retornadas ` + res.length);

            this.listReadySubject.next(this.list); // Notifica que a lista foi inicializada
          }
        },
        (err: any) => {
          console.log(`AtividadeExecutorMultiSelect - getLista ` + err);
        }
      );      
  }

  
  onSelectionChange(selected: any) {
    if (selected.length === this.list.length) {     
      this.itemSelecionado.emit(this.list); // TODA a lista completa
    } else {      
      this.itemSelecionado.emit(selected); // APENAS itens selecionados
    }
  } 
}



